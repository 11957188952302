<template>
  <div class="app-content">
    <div class="header">
      <Header v-if="$route.path !== '/login'" />
    </div>
    <div class="content">
      <div class="d-flex justify-content-center" style="height: 100vh">
        <div class="m-auto text-center">
          <b-row class="m-auto">
            <b-col cols="12" xl="12">
              <img
                class="mb-3"
                style="width: 150px"
                src="../../assets/images/cmg.png"
                alt
              />
            </b-col>
          </b-row>
          <b-row class="">
            <b-col>
              <h1 class="">403 Access Denied</h1>
              <h4>Looks like you're not authorized to aceess this page</h4>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Layouts/Header";
export default {
  components: {
    Header
  },
  created() {
    if (!this.$cookies.get("d-order-session-token")) {
      this.$router.push({ path: "/login" });
      // window.location.href = `${this.$backofficeUrl}/login`;
    }
  }
};
</script>

<style scoped></style>
